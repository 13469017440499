<template>
  <div class="my-page-payment my-page" :class="{skeleton: !state.loaded }">
    <template v-if="$store.state.investor.investorAuth === 'INVESTOR_STATUS_COMPLETE'">
      <div class="wrapper">
        <div class="book">
          <BankBook :func="check"/>
        </div>
        <div class="book">
          <BankBookWithdraw :items="['label', 'withdraw', 'change']" :callback="saveInvestCustomer"/>
        </div>
      </div>
      <ul class="guide bottom tight font-sm">
        <template v-if="state.registered">
          <li>OMC 계좌란 증권형 펀딩 투자 시 이용하는 투자 전용 가상계좌를 의미합니다.</li>
          <li>출금계좌란 OMC 계좌와 연결된 본인 명의의 은행 계좌입니다.</li>
          <li>OMC 계좌의 입출금은 등록된 "출금 계좌"를 통해서만 가능합니다.</li>
          <li>전자금융 사기 방지를 위해 출금제한시간은 48시간입니다. 예치금 입금 후 48시간 전에는 출금이 제한됩니다.</li>
        </template>
        <template v-else>
          <li>증권형 펀딩에 투자하기 위해서는 출금계좌 등록이 필요합니다.</li>
          <li>출금계좌를 등록하면, 투자금을 예치하는 OMC계좌도 자동 개설됩니다.</li>
          <li>출금계좌는 투자계좌 정보에서 변경 가능합니다.</li>
        </template>
      </ul>
    </template>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import BankBook from "@/components/BankBook";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import BankBookWithdraw from "@/components/BankBookWithdraw";
import {httpError} from "@/scripts/httpError";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "pageMyPageInvestorAccountInfo";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {BankBookWithdraw, BankBook},
  props: {
    menu: Object
  },
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "투자 계좌");

      if (store.state.investor.investorAuth !== "INVESTOR_STATUS_COMPLETE") {
        store.commit("setSwingMessage", "투자 진행을 위해 투자자 인증 화면으로 이동합니다.");
        router.replace({path: "/mypage/investor"});
      }
    });

    const state = reactive({
      loaded: false,
      registered: false
    });

    const check = (registered) => {
      state.loaded = true;
      state.registered = registered;
    };

    const saveInvestCustomer = (params) => {
      const args = {
        investorSeq: params.investorSeq,
        investorName: params.investorName,
        virtualAccountNum: params.virtualAccountNum,
        withdrawAccountCode: params.withdrawAccountCode,
        withdrawAccountNum: params.withdrawAccountNum,
      };

      if (!args.investorSeq) {
        return;
      }

      http.patch("/api/invest/customers", args).then(() => {
        store.commit("setSwingMessage", "출금 계좌 정보를 저장하였습니다.");
        store.commit("refresh");
      }).catch(httpError());
    };

    return {component, state, check, saveInvestCustomer};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-payment {
  > .wrapper {
    margin: 0 auto $px50 auto;
    max-width: $px470;

    > .book:not(:last-child) {
      margin-bottom: $px35;
    }
  }
}
</style>